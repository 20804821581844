<template>
  <div class="container">
    <div class="title">
      <div class="row">
        <div class="col-sm-12">
          <h2 style="margin: 20px 0 0 0">Combine Tests</h2>
        </div>
      </div>
    </div>
    <hr />
    <el-form ref="form" label-width="80px">
      <el-form-item label="Student" v-if="isAdmin">
        <el-select
          style="width: 100%"
          :value="uid"
          @change="selectStudent"
          placeholder="請輸入學生的名字或 Email 查詢學生"
          clearable
          filterable
          remote
          :remote-method="fetchStudents"
        >
          <el-option
            v-for="student in students"
            :key="student.id"
            :label="`${user.displayName(student.first_name, student.last_name)} (${student.email})`"
            :value="student.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Test">
        <el-select
          style="width: 100%"
          v-model="tpo"
          filterable
          @change="getTests(category)"
        >
          <el-option
            v-for="toefl in toefls"
            :key="toefl.id"
            :value="toefl.id"
            :label="toefl.title"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Type">
        <el-radio v-model="combineType" label="1" border
          >{{ $t("button.combineSectionToMock") }}</el-radio
        >
        <el-radio v-model="combineType" label="2" border
          >{{ $t("button.combineUnitToSection") }}</el-radio
        >
      </el-form-item>
      <el-form-item label="Section" v-if="combineType === '2'">
        <el-select v-model="section">
          <el-option value="reading" label="Reading"></el-option>
          <el-option value="listening" label="Listening"></el-option>
          <el-option value="speaking" label="Speaking"></el-option>
          <el-option value="writing" label="Writing"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-table
      :data="fullTest"
      style="width: 100%"
      border
      v-if="combineType === '1'"
    >
      <el-table-column prop="user_exam_id" label="ID" width="100">
        <template slot-scope="scope">
          <router-link :to="`/result?id=${scope.row.user_exam_id}`">
            {{ scope.row.user_exam_id }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="Student">
        <template slot-scope="scope">
          {{ user.displayName(scope.row.first_name, scope.row.last_name) }}
        </template>
      </el-table-column>
      <el-table-column prop="reading_score" label="Reading">
        <template slot-scope="scope">
          <el-radio
            v-if="!scope.row.exam.test.toefl_skill_id"
            :label="true"
            :value="userExamIds[singleSectionIdMap[toeflCategories.reading]] === scope.row.user_exam_id"
            @input="selectPassage(scope.row.user_exam_id, singleSectionIdMap[toeflCategories.reading])"
          ><span v-show="false">hideLabel</span></el-radio>
          <template v-else>
            <el-radio
              v-if="
                toeflCategories.reading === scope.row.exam.test.toefl_skill_id
              "
              :label="true"
              :value="userExamIds[singleSectionIdMap[scope.row.exam.test.toefl_skill_id]] === scope.row.user_exam_id"
              @input="selectPassage(scope.row.user_exam_id, singleSectionIdMap[scope.row.exam.test.toefl_skill_id])"
            ><span v-show="false">hideLabel</span></el-radio>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="listening_score" label="Listening">
        <template slot-scope="scope">
          <el-radio
            v-if="!scope.row.exam.test.toefl_skill_id"
            :label="true"
            :value="userExamIds[singleSectionIdMap[toeflCategories.listening]] === scope.row.user_exam_id"
            @input="selectPassage(scope.row.user_exam_id, singleSectionIdMap[toeflCategories.listening])"
          ><span v-show="false">hideLabel</span></el-radio>
          <el-radio
            v-if="
              toeflCategories.listening === scope.row.exam.test.toefl_skill_id
            "
            :label="true"
            :value="userExamIds[singleSectionIdMap[scope.row.exam.test.toefl_skill_id]] === scope.row.user_exam_id"
            @input="selectPassage(scope.row.user_exam_id, singleSectionIdMap[scope.row.exam.test.toefl_skill_id])"
          ><span v-show="false">hideLabel</span></el-radio>
        </template>
      </el-table-column>
      <el-table-column prop="speaking_score" label="Speaking">
        <template slot-scope="scope">
          <el-radio
            v-if="!scope.row.exam.test.toefl_skill_id"
            :label="true"
            :value="userExamIds[singleSectionIdMap[toeflCategories.speaking]] === scope.row.user_exam_id"
            @input="selectPassage(scope.row.user_exam_id, singleSectionIdMap[toeflCategories.speaking])"
          ><span v-show="false">hideLabel</span></el-radio>
          <el-radio
            v-if="
              toeflCategories.speaking === scope.row.exam.test.toefl_skill_id
            "
            :label="true"
            :value="userExamIds[singleSectionIdMap[scope.row.exam.test.toefl_skill_id]] === scope.row.user_exam_id"
            @input="selectPassage(scope.row.user_exam_id, singleSectionIdMap[scope.row.exam.test.toefl_skill_id])"
          ><span v-show="false">hideLabel</span></el-radio>
        </template>
      </el-table-column>
      <el-table-column prop="writing_score" label="Writing">
        <template slot-scope="scope">
          <el-radio
            v-if="!scope.row.exam.test.toefl_skill_id"
            :label="true"
            :value="userExamIds[singleSectionIdMap[toeflCategories.writing]] === scope.row.user_exam_id"
            @input="selectPassage(scope.row.user_exam_id, singleSectionIdMap[toeflCategories.writing])"
          ><span v-show="false">hideLabel</span></el-radio>
          <el-radio
            v-if="
              toeflCategories.writing === scope.row.exam.test.toefl_skill_id
            "
            :label="true"
            :value="userExamIds[singleSectionIdMap[scope.row.exam.test.toefl_skill_id]] === scope.row.user_exam_id"
            @input="selectPassage(scope.row.user_exam_id, singleSectionIdMap[scope.row.exam.test.toefl_skill_id])"
          ><span v-show="false">hideLabel</span></el-radio>
        </template>
      </el-table-column>
      <el-table-column prop="total_score" label="Score" />
      <el-table-column prop="created_at" label="Date" width="200px">
        <template slot-scope="scope">
          {{ moment(scope.row.created_at).format("YYYY-MM-DD hh:mm:ss") }}
        </template>
      </el-table-column>
    </el-table>
    <el-table
      :data="singleSections"
      style="width: 100%"
      border
      v-if="combineType === '2'"
    >
      <el-table-column label="ID" width="100">
        <template slot-scope="scope">
          <router-link :to="`/result?id=${scope.row.user_exam_id}`">
            {{ scope.row.user_exam_id }}
          </router-link>
        </template>
      </el-table-column>
      <el-table-column label="Student">
        <template slot-scope="scope">
          {{ user.displayName(scope.row.first_name, scope.row.last_name) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="passage"
        v-for="(item, chapterIndex) in passageNum[section]"
        :key="item"
        :label="'Passage' + item"
      >
        <template slot-scope="scope">
          <el-radio
            v-if="!scope.row.exam.test.order"
            :label="true"
            :value="userExamIds[scope.row.exam.test.toefl_chapter_ids[chapterIndex]] === scope.row.user_exam_id"
            @input="selectPassage(scope.row.user_exam_id, scope.row.exam.test.toefl_chapter_ids[chapterIndex])"
          ><span v-show="false">hideLabel</span></el-radio>
          <template v-else>
            <el-radio
              v-if="scope.row.exam.test.order === item"
              :label="true"
              :value="userExamIds[scope.row.exam.test.id] === scope.row.user_exam_id"
              @input="selectPassage(scope.row.user_exam_id, scope.row.exam.test.id)"
            ><span v-show="false">hideLabel</span></el-radio>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="total_score" label="Score" />
      <el-table-column prop="created_at" label="Date">
        <template slot-scope="scope">
          {{ moment(scope.row.created_at).format("YYYY-MM-DD hh:mm:ss") }}
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center" style="margin:20px 0">
      <el-button type="success" @click="combineTests">Combine</el-button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { user } from "@ivy-way/material";
import role from "@/mixins/role.js";
import TOEFL from "@/apis/toefl";

export default {
  metaInfo() {
    return {
      title: "Combine Tests - " + this.CompanyName
    };
  },

  components: {},

  mixins: [role],

  props: [],
  data() {
    return {
      uid: "",
      tpo: "",
      moment,
      students: [],
      toefls: [],
      section: "reading",
      combineType: "1",
      fullTest: [],
      singleSectionIdMap: {},
      singleSections: [],
      targetExamId: null,
      userExamIds: {},
      passageNum: {
        reading: 3,
        listening: 5,
        speaking: 4,
        writing: 2
      },
      category: "single_section",
      combineId: [],
      passages: [],
      sections: []
    };
  },
  computed: {
    user() {
      return user;
    },
    isAdmin() {
      return this.isRoleAdmin();
    },
    toeflCategories() {
      return {
        reading: 1,
        listening: 2,
        speaking: 3,
        writing: 4,
        1: "reading",
        2: "listening",
        3: "speaking",
        4: "writing"
      };
    },
    canCombine() {
      let canCombine = false;
      if (this.combineType === "1") {
        if (!this.isRepeat(this.sections)) {
          canCombine = true;
        }
      } else {
        if (this.combineId.length === this.passageNum[this.section]) {
          if (!this.isRepeat(this.passages)) {
            canCombine = true;
          }
        }
      }
      return canCombine;
    }
  },
  watch: {
    tpo() {
      this.getCombineExamInfo();
    },
    section() {
      if (this.combineType === "2") {
        this.category = this.section;
      } else {
        this.category = "single_section";
      }
      this.getCombineExamInfo();
      this.getTests(this.category);
    },
    combineType() {
      if (this.combineType === "2") {
        this.category = this.section;
      } else {
        this.category = "single_section";
      }
      this.getCombineExamInfo();
      this.getTests(this.category);
    }
  },
  created() {
    if (!this.isAdmin) {
      this.uid = this.$store.getters["user/getProfile"].id;
      this.getToeflTitle(this.uid);
    }
  },
  methods: {
    selectPassage(userExamId, id) {
      this.userExamIds = {
        ...this.userExamIds,
        [id]: userExamId
      };
    },
    async getCombineExamInfo() {
      if (!this.tpo) return;

      let combineExamId = null;
      if (this.combineType === "1") {
        combineExamId = await TOEFL.getExamIdByToeflId(this.tpo);
        let workSingleSectionIdMap = {};
        combineExamId.toefl_chapters.forEach((examId) => {
          workSingleSectionIdMap[this.toeflCategories[examId.name]] = examId.id;
        });
        this.singleSectionIdMap = { ...workSingleSectionIdMap };
      } else {
        combineExamId = await TOEFL.getExamIdByToeflId(this.tpo, this.section);
      }
      this.targetExamId = combineExamId.target_exam_id;
      let userExamIds = {};
      combineExamId.toefl_chapters.forEach(({ id }) => {
        userExamIds[id] = null;
      });
      this.userExamIds = userExamIds;
    },
    async fetchStudents(keyword) {
      const { users: { data } } = await TOEFL.fetchStudents(keyword);
      this.students = data;
    },
    selectStudent(userId) {
      this.uid = userId;
      this.getToeflTitle(userId);
    },
    async getToeflTitle(userId) {
      if (userId) {
        const { toefls } = await TOEFL.getAllToeflTitle(userId);
        this.toefls = toefls;
      } else {
        this.toefls = [];
      }
    },
    async getTests(category) {
      const res = await TOEFL.getTests({
        user_id: this.uid,
        toefl_id: this.tpo,
        category: category
      });
      if (this.combineType === "1") {
        this.fullTest = res.data;
      } else {
        this.singleSections = res.data;
      }
    },
    isRepeat(arr) {
      let hash = {};
      for (let i in arr) {
        if (hash[arr[i]]) {
          return true;
        }
        hash[arr[i]] = true;
      }
      return false;
    },
    async combineTests() {
      const params = {
        user_exam_ids: this.userExamIds,
        target_exam_id: this.targetExamId,
        user_id: this.uid,
        category: this.combineType === "1" ? "full_mock" : "single_section"
      };
      await TOEFL.combineTests(params);
      this.$message.success(this.$t("message.combineSuccessfully"));
      this.$router.go(-1);
    }
  }
};
</script>

<style scoped></style>
